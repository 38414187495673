import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './primaria.css';

class Primaria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="container-fluid">
                <div className="cont-servi-principal row">
                    {/* <div className="cont-servi col-12">
                        <div className="container row"> */}
                    <div className="cont-imagen1pri col-7">
                        <div className="imagen1pri">
                            <img className="card-img-top" id="img1pri" src="/imgprimaria/foto1.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen2pri">
                            <img className="card-img-top" id="img2pri" src="/imgprimaria/foto2.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen3pri">
                            <img className="card-img-top" id="img3pri" src="/imgprimaria/foto3.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen4pri">
                            <img className="card-img-top" id="img4pri" src="/imgprimaria/clickrojo.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen5pri">
                            <img className="card-img-top" id="img5pri" src="/imgprimaria/clickazul.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen6pri">
                            <img className="card-img-top" id="img6pri" src="/imgprimaria/clickverde.png" alt="Card image cap"/>
                        </div>
                    </div>
                    <div className="eslogan1pri col-4">
                        <p className="titulopri">Primaria</p>
                        <div className="cuerpo-textopri">
                            <p className="cuerpotexto1pri">La educación primaria también conocida como la educación elemental que se produce desde los 6 hasta los 12 años. Siendo los estudios básicos donde aseguran la alfabetización, es decir se enseña a leer, escribir, cálculos básicos y otros. Teniendo como finalidad proporcionar a los estudiantes una formación que haga posible el desarrollo de las habilidades individuales motrices, de equilibrio personal, de relación y actuación social.</p>
                            <p className="cuerpotexto2pri">Para ofrecerle nuestra mejor atención y asegurarles una educación de calidad manejamos un máximo de 20 alumnos por salón. Los servicios que ofrecemos a los estudiantes de primaria son:</p>
                            
                            <p className="cuerpotexto3pri"><b id="cuerpotexto3pri">Clases pedagócicas.</b></p>
                            <p className="cuerpotexto4pri"><b id="cuerpotexto4pri">Clases de Inglés todos los días.</b></p>
                            <p className="cuerpotexto5pri"><b id="cuerpotexto5pri">Educación física.</b></p>
                            <p className="cuerpotexto6pri"><b id="cuerpotexto6pri">Deporte.</b></p>
                            <p className="cuerpotexto7pri"><b id="cuerpotexto7pri">Plataforma de KidsBook.</b></p>
                            <p className="cuerpotexto8pri"><b id="cuerpotexto8pri">Salones con aires acondicionados.</b></p>
                        </div>
                        <div className="links">
                            <a href="https://www.libro1.institutobilinguelasnaciones.com/fpdf/reportes/requisitosPrimaria.php" target="__blank"><p className="cuerpotexto11"><b id="cuerpotexto5">Ir a Requisitos Primaria...</b></p> </a>
                            <a><Link className="linkp" to="/planillap"><p className="cuerpotexto11"><b className="planilla">Planilla.</b><b className="planilla1">Primaria...</b></p></Link></a>
                        </div>

                    </div>
                </div>
{/*                                 
                <Footer /> 
            </div>                                    
                </div> */}
            </div>
        )
    }
}export default Primaria;    