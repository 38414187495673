import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './contacto.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';


class Contacto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
            nombrecontacto:'',
            apellidocontacto:'',
            emailcontacto:'',
            celularcontacto:'',
            mensajecontacto:'',
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }
    enviar =() => {

    }

enviar=()=>{
    if (this.state.nombrecontacto.length >0) {
        let data = new FormData();
        data.append('nombre', this.state.nombrecontacto );
        data.append('apellido', this.state.apellidocontacto);
        data.append('email', this.state.emailcontacto);
        data.append('celular', this.state.celularcontacto);
        data.append('mensaje', this.state.mensajecontacto);
        data.append('boton', 'CorreoContacto');
        let config = {
            headers : {
                'Content-Type' : 'multipart/form-data'
            }
        }
        const xurl = 'https://institutobilinguelasnaciones.com/colegio/correocontacto.php';
        axios.post(xurl, data, config)
        .then(res => {
            if (res.data.variable1) {
                console.log('paso');
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Envio de Correos',
                    cuerpoM: 'El correo se envio exitosamente a el paciente',
                })
                
            } else {
                console.log('no paso');
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Envio de Correo',
                    cuerpoM: 'Hubo un error, No se envio el correo a el paciente, verifique por favor',
                })
            }
        })
    }else {
        this.setState({
            mensajealerta: true,
            tituloM: 'Menu Envio de Correo',
            cuerpoM: 'Todos los campos deben estar llenos',
        })
    }
}


    render(){
     
        return(
            <div className="container">
                <div className="cont-servi-principal col-12">
                {/* <div className="row"> */}
                <div className="cont-servi">
               
                    <div className="eslogan1con col-12">
                        <p className="titulocon">Contáctanos</p>
                    </div>
                    <div className="row">
                        <div className="formulario col-4"> 
                            <div className=""> 
                                <p className="titulotlf">Solicite información</p>
                            </div>
                            <div className="nombrecontacto">
                                <label className="opcion" >Nombre</label>
                                <span className="asterisco">*</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="nombrecontacto"
                                    id="nombrecontacto"
                                    autoComplete="off"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.nombrecontacto.toUpperCase()}
                                />
                            </div>
                            <div className="apellidocontacto ">
                                <label className="opcion" >Apellido</label>
                                <span className="asterisco">*</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="apellidocontacto"
                                    id="nombrecontacto"
                                    autoComplete="off"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.apellidocontacto.toUpperCase()}
                                />
                            </div>
                            <div className="emailcontacto ">
                                <label className="opcion" >Email</label>
                                <span className="asterisco">*</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="emailcontacto"
                                    id="emailcontacto"
                                    autoComplete="off"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.emailcontacto.toUpperCase()}
                                />
                            </div>
                            <div className="celularcontacto ">
                                <label className="opcion" >Celular</label>
                                <span className="asterisco">*</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="celularcontacto"
                                    id="celularcontacto"
                                    autoComplete="off"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.celularcontacto.toUpperCase()}
                                />
                            </div>
                            <div className="mensajecontacto ">
                                <label className="opcion" >Mensaje</label>
                                <span className="asterisco">*</span>
                                <textarea
                                    className="form-control"
                                    name="mensajecontacto"
                                    id="mensajecontacto"
                                    rows="6"
                                    cols="150"
                                    value={this.state.mensajecontacto}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className="accionesb ">
                                <button className="botonm" onClick={this.enviar.bind(this)}>Enviar</button>
                            </div>

                            
                        </div>
                        
                        <div className="telefonos col-3">  
                            <div className=""> 
                                <p className="titulotlf">Teléfonos</p>
                            </div>
                            <div className=""> 
                                <p className="numerotlf">+507-831-7601</p>
                            </div>
                            <div className=""> 
                                <p className="numerotlf">+507-831-7602</p>
                            </div>
                            <div className=""> 
                                <p className="numerotlf">+507-6330-9078</p>
                            </div>
                            <div className="cont-correo "> 
                                <p className="contcorreo">Correo</p>
                            </div>
                            <div className=""> 
                                <p className="correo1">info@institutobilinguelasnaciones.com</p>
                            </div>
                            <div className="socialesc">                    
                                <a href="https://instagram.com/colegiobilinguelasnaciones?igshid=1sg8v3kxnc0r5" target="__blank"><InstagramIcon style={{ fontSize: 60,  marginRight:30,color:'2bbbad' }}/></a>
                                <a href="https://www.facebook.com/278054962801682" target="__blank"><FacebookIcon style={{ fontSize: 60, color:'2bbbad'}}/></a>
                            </div>                     
                        </div>
                    

                        <div className="cont-mapa col-4">
                            <div className=""> 
                                    <p className="titulomapa">¡Ven a conocer nuestras instalaciones!</p>
                            </div>
                            <iframe className="mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1393.3506359990226!2d-79.53049433108445!3d8.972998788497085!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8faca8ea22dc5c0f%3A0xfdf615ae1f03dfc5!2sColegio%20Bilingue%20Las%20Naciones!5e0!3m2!1sen!2sdo!4v1595003315692!5m2!1sen!2sdo" ></iframe>

                        </div>
                    </div>                   
                </div>
                
            </div>        
                
        </div>
            
        )
    }
}export default Contacto;    