import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './premedia.css';

class Premedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="container-fluid">
                <div className="cont-servi-principal row">
                    <div className="cont-imagen1pre col-7">
                        <div className="imagen1pre">
                            <img className="card-img-top" id="img1pre" src="/imgpremedia/foto1.jpg" alt="Card image cap"/>
                        </div>
                        <div className="imagen2pre">
                            <img className="card-img-top" id="img2pre" src="/imgpremedia/foto2.jpg" alt="Card image cap"/>
                        </div>
                        <div className="imagen3pre">
                            <img className="card-img-top" id="img3pre" src="/imgpremedia/foto3.jpg" alt="Card image cap"/>
                        </div>
                        
                    </div>
                    <div className="eslogan1pre col-4">
                        <p className="titulopre">Pre-media y Media</p>
                        <div className="cuerpo-textopre">
                            <p className="cuerpotexto1pre">Media y Pre-media son las etapas de la eduación posterior a la enseñanza primaria y antes de la superior. Tiene como objetivo capacitar al alumno para poder iniciar estudios superior. Puede ser común para todos o diversificada en vías formativas. Las modalidades a la vez pueden tener diversas especializaciones y orientaciones que permiten formarse en temas específicos.</p>
                            <p className="cuerpotexto2pre">Los servicios que ofrecemos a los estudiantes son:</p>
                            
                            <p className="cuerpotexto3pre"><b id="cuerpotexto3pre">Salón máximo de 20 alumnos.</b></p>
                            <p className="cuerpotexto4pre"><b id="cuerpotexto4pre">Horario de 7:30am a 2:00pm.</b></p>
                            <p className="cuerpotexto5pre"><b id="cuerpotexto5pre">Clases pedagócicas.</b></p>
                            <p className="cuerpotexto6pre"><b id="cuerpotexto6pre">Clases de Inglés todos los días.</b></p>
                            <p className="cuerpotexto7pre"><b id="cuerpotexto7pre">After School (estudios dirigidos).</b></p>
                            <p className="cuerpotexto8pre"><b id="cuerpotexto8pre">Deporte.</b></p>
                            <p className="cuerpotexto9pre"><b id="cuerpotexto9pre">Salones con aires acondicionados.</b></p>
                        </div>
                        <div className="links">
                            <a href="https://www.libro1.institutobilinguelasnaciones.com/fpdf/reportes/requisitosPrimaria.php" target="__blank"><p className="cuerpotexto11"><b id="cuerpotexto5">Ir a Requisitos Pre media y Media...</b></p> </a>
                           <a><Link className="linkp" to="/planillap"><p className="cuerpotexto11"><b className="planilla">Planilla.</b><b className="planilla1">Pre-media y Media....</b></p></Link></a>
                        </div>

                    </div>
                </div>                        
            </div>
        )
    }
}export default Premedia;    