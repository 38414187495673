import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";
import Slider from "react-slick";


//componentes
import Footer from '../footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home.css';
import logo1 from '../../imagenes/logocln.png';
//import img2 from '../../imagenes/Flechad.gif'
//import Alert from '../../modal/alert';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
      const servicio = [
            {
                img: '/imagen/servicio1.jpeg',
                title: 'Servicio de Camaras',
                text: <p className="teh">La institución le da a el representante la opción de poder visualizar a sus hijo(a) permanentemente durante la estadia en la institución.</p>},
            {
                img: '/imagen/servicio2.JPG',
                title: 'Plataforma KidsBook',
                text: <p className="teh">Un sistema de gestión digital para satisfacer de forma sencilla la comunicación entre el representante y la institución.</p>},
            {
                img: '/imagen/foto3.jpeg',
                title: 'Bilingue',
                text: <p className="teh">La Prioridad del Colegio Bilingue las Naciones es el que nuestros alumnos puedan conversar el idioma ingles fluidamente.</p>},
            {
                img: '/imagen/foto2.jpeg',
                title: 'Atención Exclusiva',
                text: <p className="teh">Le brindamos a nuestros alumnos profesores y técnicos especialistas en cada área, para un mayor rendimiento académico.</p>},
        ]
        const products = [
            {
              img: '/imagen/foto1.jpeg',
            },
            {
              img: '/imagen/foto3.jpeg',
            },
            {
                img: '/imagen/foto4.jpeg',
              },
            {
                img: '/imagen/foto5.jpeg',
              },
              {
                img: '/imagen/foto6.jpeg',
              },
              {
                img: '/imagen/foto7.jpeg',
              },
              {
                img: '/imagen/foto8.jpeg',
              },
              {
                img: '/imagen/foto9.jpeg',
              },
              {
                  img: '/imagen/foto10.jpeg',
                },
              {
                  img: '/imagen/foto11.jpeg',
                },
                {
                  img: '/imagen/foto12.jpeg',
                },  
                // {
                //     img: '/imagen/foto13.jpeg',
                // },
                {
                    img: '/imagen/foto14.jpeg',
                },
                // {
                //     img: '/imagen/foto15.jpeg',
                // },
                // {
                //     img: '/imagen/foto16.jpeg',
                // },  
                // {
                //     img: '/imagen/foto17.jpeg',
                //   },  
                  {
                      img: '/imagen/foto18.jpeg',
                  },
                  {
                      img: '/imagen/foto19.jpeg',
                  },
                  {
                      img: '/imagen/foto20.jpeg',
                  },
                  {
                      img: '/imagen/foto21.jpeg',
                  },  
                  {
                    img: '/imagen/foto22.jpeg',
                },
                {
                    img: '/imagen/foto23.jpeg',
                },  
                {
                    img: '/imagen/foto24.jpeg',
                },
                {
                    img: '/imagen/foto25.jpeg',
                },  
                {
                    img: '/imagen/foto26.jpeg',
                },
                {
                    img: '/imagen/foto27.jpeg',
                },  
                {
                    img: '/imagen/foto28.jpeg',
                },
                {
                    img: '/imagen/foto29.jpeg',
                },  
                {
                    img: '/imagen/foto30.jpeg',
                },  

          ]
        const settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            //centerMode: true, // enable center mode
            centerPadding: '50px',
            autoplay: true
        };
        const config = {
            Type: true,
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            //centerMode: true, // enable center mode
            centerPadding: '50px',
            
            autoplay: true
        };
       
        return(
            <div className="container-fluid">
                <div className="cont-home-princ row">
                    <div className="cont-home1 col-11">
                    
                        <Slider {...settings}>
                            {products.map((x, i) => {
                                return <div key={i} className="img-card">
                                    <img className="img1" src={x.img} />
                                    {/* <div class="card-body">
                                        <div className="card-title">{x.title}</div>
                                        <div className="card-text">{x.text}</div>
                                    </div> */}
                                </div>
                            })}
                        </Slider>
                    </div>
                </div>
                <div className="row">
                    <div className="eslogan col-12">
                        <p className="biblico">"Enseñando el buen camino"</p>
                    </div>
                </div>
                <div className="row">
                    <div className="cont-home1 col-12">
                        <Slider {...config}>
                            {servicio.map((x, i) => {
                                return <div key={i} className="img-card">
                                    <img className="img" src={x.img} />
                                    <div class="card-body">
                                        <div className="card-title">{x.title}</div>
                                        <div className="card-text">{x.text}</div>
                                    </div>
                                </div>
                            })}
                        </Slider>
                    </div>
                </div> 
                <div className="row">
                    <div className="eslogan col-12">
                        <p className="biblico1">“Instruye al niño en su camino, y aún cuando</p> 
                        <p className="biblico2">fuere viejo no se apartará de él."</p> 
                        <p className="biblico3">Proverbios 22:6</p>                                  
                    </div>                    
                </div>
                    
                    
            </div>
        )
    }
}export default Home;    