import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './maternal.css';

class Maternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return( 
            <div className="container-fluid">
                <div className="cont-servi-principal row">
                    {/* <div className="cont-servi col-12">
                        <div className="container row"> */}
                    <div className="cont-imagen1 col-5">
                        <div className="imagen1">
                            <img className="card-img-top" id="img1" src="/imgmaternal/foto1.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen2">
                            <img className="card-img-top" id="img2" src="/imgmaternal/foto3.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen3">
                            <img className="card-img-top" id="img3" src="/imgmaternal/foto2.jpeg" alt="Card image cap"/>
                        </div>
                    </div>
                    <div className="eslogan1 col-5">
                        <div className="imagen10">
                            <img className="card-img-top" id="img10" src="/imgmaternal/avion.png" alt="Card image cap"/>
                        </div>
                        <p className="titulo">Maternal</p>
                        <div className="cuerpo-texto">
                            <p className="cuerpotexto1">Maternales son niveles  para los más pequeños dirigido a bebés desde los  4 meses hasta niños de 3 años. Está dividido en 3 niveles de acuerdo a las edades</p>
                            <p className="cuerpotexto2"><b id="cuerpotexto2">Maternal A:</b> Salón de bebes de 4 meses hasta niños de 1 año</p>
                            <p className="cuerpotexto3"><b id="cuerpotexto3">Maternal B:</b> Salón de niños de 2 años</p>
                            <p className="cuerpotexto4"><b id="cuerpotexto4">Maternal C:</b> Salón de niños de 3 años</p>
                            <p className="cuerpotexto1">Para ofrecerle nuestra mejor atención a los pequeños manejamos un máximo de 15 alumnos por salón. Cada salón tiene su respectiva teacher titular y una asistente (ambas son licenciadas) que les brinda la educación para cumplir con los hitos del desarrollo. Los servicios que ofrecemos para los niños de maternales son:</p>
                            <p className="cuerpotexto6"><b id="cuerpotexto2">Cámaras.</b></p>
                            <p className="cuerpotexto7"><b id="cuerpotexto3">Clases pedagócicas.</b></p>
                            <p className="cuerpotexto8"><b id="cuerpotexto4">Clases de Inglés todos los días.</b></p>
                            <p className="cuerpotexto9"><b id="cuerpotexto3">Clases de música.</b></p>
                            <p className="cuerpotexto9"><b id="cuerpotexto3">Deporte.</b></p>
                            <p className="cuerpotexto9"><b id="cuerpotexto3">Parque para las diferentes edades.</b></p>
                            <p className="cuerpotexto10"><b id="cuerpotexto4">Plataforma de KidsBook.</b></p>
                            <p className="cuerpotexto10"><b id="cuerpotexto4">Horarios de 7:00AM a 6:00PM.</b></p>
                            <p className="cuerpotexto10"><b id="cuerpotexto4">Uniforme.</b></p>                         
                        </div>
                        <div className="links">
                            <a href="https://www.libro1.institutobilinguelasnaciones.com/fpdf/reportes/requisitosMaternal.php" target="__blank"><p className="cuerpotexto11"><b id="cuerpotexto5">Ir a Requisitos Maternal...</b></p> </a>
                           <a><Link className="linkp" to="/planillam"><p className="cuerpotexto11"><b className="planilla">Planilla.</b><b className="planilla1">Maternal-Pre escolar...</b></p></Link></a>
                        </div>
                    </div>
                {/* </div> */}
                    <div className="cont-imagen1">
                        <div className="imagen4">
                            <img className="card-img-top" id="img4" src="/imgmaternal/sol.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen5">
                            <img className="card-img-top" id="img5" src="/imgmaternal/casa.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen6">
                            <img className="card-img-top" id="img6" src="/imgmaternal/abeja.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen7">
                            <img className="card-img-top" id="img7" src="/imgmaternal/pollito.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen8">
                            <img className="card-img-top" id="img8" src="/imgmaternal/papagallo.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen9">
                            <img className="card-img-top" id="img9" src="/imgmaternal/cohete.png" alt="Card image cap"/>
                        </div>
                        {/* <div className="imagen10">
                            <img className="card-img-top" id="img10" src="/imgmaternal/avion.png" alt="Card image cap"/>
                        </div> */}
                    </div> 
                    
                    {/* </div>         */}
                            
                </div>
            </div>
        )
    }
}export default Maternal;    