import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "mdbreact";
//import axios from 'axios';
import {Link} from "react-router-dom";


//componentes
//import TwitterIcon from '@material-ui/icons/Twitter';
//import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Icon from '@material-ui/core/Icon';
import './header.css';
import logo1 from '../../imagenes/Logo.png'
//import img2 from '../../imagenes/Flechad.gif'
//import Alert from '../../modal/alert';

class Headers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancion: '',
            onActive:false,
            mensajealerta:false,
            codigo:'',
            tcaso:'',
            guardarOn:false,
            acordion3: false,
            isOpen: false
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    activar = () => {
        if (this.state.onActive){
            this.setState({ onActive: false })
        } else {
            this.setState({ onActive: true })
        }
    }
    verificar = (e) => {
        // console.log([e.target.checked])
        if ((e.target.checked)===true){
           this.setState({[e.target.getAttribute('id')]: true});
        }else{
            this.setState({[e.target.getAttribute('id')]: false});

        }
}

    componentDidMount(){
        
    }
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
      }

    render(){
           
        return(
            <div className="cont-header-princ row"> 
            <div className="col-9">
                <div className="logoh col-12">
                    <div className="row">
                        <div className="cont-logop">
                            <Link to="/"><img className="logo" src={logo1} /></Link>
                        </div>
                        <div className="cont-texto">
                            <p className="t1">Colegio</p>
                            <p className="t1">Bilingue</p>
                            <p className="t1">Las Naciones</p>
                        </div>
                    </div>
                </div>
                
                <div className="navbart">
                <nav className="navbar navbar-expand-sm navbar-dark sticky-top ">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Inicio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/nosotros">Nosotros</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle " id="navbardrop" data-toggle="dropdown">
                                Servicios
                            </a>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/maternal">Maternal</Link>
                                <Link className="dropdown-item" to="/preescolar">Pre escolar</Link>
                                <Link className="dropdown-item" to="/primaria">Primaria</Link>
                                <Link className="dropdown-item" to="/premedia">Premedia - Media</Link>
                                <Link className="dropdown-item" to="/superior">Superior</Link>
                            </div>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" to="/fotos">Fotos</Link>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle " id="navbardrop" data-toggle="dropdown">
                                Admisiones
                            </a>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="#"><b className="titulo-planilla">**** M A T E R N A L ***</b> </Link>
                                    <Link className="dropdown-item" to="/planillam"><b className="planilla">Planilla.</b><b className="planilla1"> Maternal-Pre escolar.</b> </Link>
                                    <Link className="dropdown-item" to="/planillamc"><b className="planilla">Contrato.</b> <b className="planilla1">Alumno Regulares.</b> </Link>
                                    <Link className="dropdown-item" to="/archivosUp"><b className="planilla">Subir</b><b className="planilla1"> Archivos a la Institución .</b></Link>
                                <Link className="dropdown-item" to="#"><b className="titulo-planilla">**** P R I M A R I A ***</b> </Link> 
                                    <Link className="dropdown-item" to="/planillap"><b className="planilla">Planilla.</b> <b className="planilla1">Primaria-Premedia-Media.</b> </Link>
                                    <Link className="dropdown-item" to="/planillapc"><b className="planilla">Contrato.</b><b className="planilla1"> Alumno Regulares.</b> </Link>
                                    <Link className="dropdown-item" to="/archivosUp"><b className="planilla">Subir</b><b className="planilla1"> Archivos a la Institución .</b></Link>
                                    {/* <Link className="dropdown-item" to="#">Superior</Link> */}
                            </div>
                        </li>

                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/reportepago">Reportar_Pago</Link>
                        </li> */}

                        <li className="nav-item">
                            <Link className="nav-link" to="/contacto">Contacto</Link>                        
                        </li>
                        {/* <li className="nav-item">
                        <a className="nav-link">Preguntas</a>
                        </li> */}

                    </ul>
                </nav>
                </div>
            </div>
            <div className="cont-sociales col-3">
                <div className="sociales">
                    
                    <a href="https://instagram.com/colegiobilinguelasnaciones?igshid=1sg8v3kxnc0r5" target="__blank"><InstagramIcon style={{ fontSize: 40,  marginRight:30,color:'fff' }}/></a>
                    <a href="https://www.facebook.com/278054962801682" target="__blank"><FacebookIcon style={{ fontSize: 40, color:'fff'}}/></a>
                </div>                     
            </div>
            </div>


        )
    }
}export default Headers;    